import {fetchAPI} from './api'

export async function getHeaderData() {
    const query = `
      {
        themeOptionsHeader {
            header {
              headerLogo {
                altText
                sourceUrl
              }
              ctaButton {
                title
                url
              }
            }
        }
      }
    `;
  
    try {
      const data = await fetchAPI(query);
  
      // Extracting the theme footer settings data
      const headerData = data?.themeOptionsHeader ?? null;
    
      return headerData;
    } catch (error) {
      console.error('Error fetching header data settings:', error);
      return null;
    }
  }