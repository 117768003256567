import { fetchAPI } from './api';

export interface MenuItem {
  label: string;
  id: string;
  path: string;
  uri: string;
}

export interface MenuData {
  locations: string[];
  name: string;
  slug: string;
  menuId: string;
  menuItems: {
    nodes: MenuItem[];
  };
}

// Define the GraphQL query to get all menus
export async function getAllMenus(): Promise<MenuData[]> {
  const GET_ALL_MENUS_QUERY = `
    query GetAllMenus {
      menus {
        nodes {
          locations
          name
          slug
          menuId
          menuItems {
            nodes {
              label
              id
              path
              uri
            }
          }
        }
      }
    }
  `;

  try {
    const data = await fetchAPI(GET_ALL_MENUS_QUERY);
    return data?.menus?.nodes;
  } catch (error) {
    console.error('Error fetching all menus:', error);
    throw new Error('Failed to fetch all menus');
  }
}

export function getMenuByLocation(allMenus: MenuData[], locationName: string): MenuData | null {
  return allMenus.find((menu) => menu.locations.includes(locationName)) || null;
}

export async function getAllServiceMenu(menuId: string): Promise<MenuData | null> {
  const GET_MENU_QUERY = `
    query GetMenu {
      menu(id: "${menuId}", idType: DATABASE_ID) {
        id
        name
        menuItems(first: 100, where: {parentId: "0"}) {
          nodes {
            label
            id
            path
            uri
            childItems {
              nodes {
                 label
                  id
                  path
                  uri
              }
            }
          }
        }
      }
    }
  `;

  try {
    const data = await fetchAPI(GET_MENU_QUERY);
    return data?.menu || null;
  } catch (error) {
    console.error('Error fetching menu:', error);
    throw new Error('Failed to fetch menu');
  }
}